@keyframes slideleft {
  from {
    background-position: 0%;
  }

  to {
    background-position: 90000%;
  }
}

@-webkit-keyframes slideleft {
  from {
    background-position: 0%;
  }
  to {
    background-position: 90000%;
  }
}

#mountains {
  /* background-image: url("/assets/decor.svg"); */
  background-size: cover;
  background-repeat: repeat-x;
  animation: slideleft 6000s infinite linear;
  -webkit-animation: slideleft 6000s infinite linear;
  /*
  -webkit-clip-path: inset(10% 10% 10% 10% round 30%, 30%);
  clip-path: inset(10% 10% 10% 10% round 30%, 30%); */
}
